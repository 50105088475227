import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog/src/templates/blog-post-template.tsx";
import { BoldText } from '../../components/bold-text/bold-text';
import { InlineCode } from '../../components/inline-code/inline-code';
import { ItalicText } from '../../components/italic-text/italic-text';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Benachrichtigungen (Alerts) sind ein `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/ARIA/apg/patterns/alert/"
      }}>{`gängiges Element`}</a>{` auf Websites.
Sie lenken die Aufmerksamkeit der Nutzer:innen auf eine wichtige Nachricht, ohne den aktuellen Workflow zu unterbrechen.
Zum Beispiel: `}<ItalicText mdxType="ItalicText">{`„Artikel wurde dem Warenkorb hinzugefügt“`}</ItalicText>{`
oder `}<ItalicText mdxType="ItalicText">{`„Login ist fehlgeschlagen: Prüfen Sie Benutzernamen/Kennwort“`}</ItalicText>{`.`}</p>
    <p>{`Dank der neuen `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/API/Popover_API"
      }}>{`Popover API`}</a>{` und der neuen Möglichkeiten von
CSS-Animationen wird es bald viel einfacher werden, barrierefreie und schön animierte Alerts zu erstellen. Ich habe eine
Demo vorbereitet, die ihr ausprobieren könnt.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAMCBAX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAFqb2WPJh//xAAaEAACAgMAAAAAAAAAAAAAAAABEQACECEj/9oACAEBAAEFAjS0e3HzBx//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAbEAACAQUAAAAAAAAAAAAAAAAAEAESMUFRcf/aAAgBAQAGPwKrGiSy6v/EABsQAAIDAAMAAAAAAAAAAAAAAAERACExEEGR/9oACAEBAAE/IS0HhsUNYvIC7KE3VVDWy+H/2gAMAwEAAgADAAAAEIAP/8QAFREBAQAAAAAAAAAAAAAAAAAAARD/2gAIAQMBAT8QJ//EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAECAQE/EGf/xAAbEAEAAwEBAQEAAAAAAAAAAAABABEhMUGBsf/aAAgBAQABPxAdPPAX78ggAAB7SR11tzPIw7UdN8yLRobf7KrDyf/Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Ein Mann spricht in ein Megaphon",
          "title": "Ein Mann spricht in ein Megaphon",
          "src": "/static/8fcaebdccf527293c6aa934e152754c7/e5166/pexels-thirdman-megaphone.jpg",
          "srcSet": ["/static/8fcaebdccf527293c6aa934e152754c7/f93b5/pexels-thirdman-megaphone.jpg 300w", "/static/8fcaebdccf527293c6aa934e152754c7/b4294/pexels-thirdman-megaphone.jpg 600w", "/static/8fcaebdccf527293c6aa934e152754c7/e5166/pexels-thirdman-megaphone.jpg 1200w", "/static/8fcaebdccf527293c6aa934e152754c7/b17f8/pexels-thirdman-megaphone.jpg 1600w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Foto: © Thirdman / pexels.com`}</em></p>
    <p>{`Falls ihr mit der Popover API noch nicht vertraut seid, solltet ihr zuerst meinen Artikel
`}<a parentName="p" {...{
        "href": "/de/popover-api-accessibility.de/"
      }}>{`„Einfach herausragend! Die neue Popover API und CSS Anchor Positioning“`}</a>{` lesen.
Ich werde hier die Grundlagen nicht wiederholen und mich stattdessen auf den speziellen Anwendungsfall konzentrieren.`}</p>
    <h2>{`Barrierefreie Alerts Demo`}</h2>
    <p>{`Hier ist meine Demo für barrierefreie Alerts mit der Popover API. Ihr könnt sowohl die Nachricht als auch die Dauer
und Position der Meldung anpassen. Probiert es aus:`}</p>
    <iframe src="https://alexlehner86.github.io/accessible-popover-alert/" title="Barrierefreie Alerts Demo" loading="lazy"></iframe>
    <p>{`Das Alert erscheint über allen anderen Elementen der Seite und wird von Screenreadern automatisch vorgelesen. Ich
habe dieses Verhalten mit `}<a parentName="p" {...{
        "href": "https://www.freedomscientific.com/products/software/jaws/"
      }}>{`JAWS`}</a>{`,
`}<a parentName="p" {...{
        "href": "https://www.nvaccess.org/download/"
      }}>{`NVDA`}</a>{`, `}<a parentName="p" {...{
        "href": "https://support.google.com/accessibility/android/answer/6283677?hl=en"
      }}>{`TalkBack`}</a>{`
und `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/VoiceOver"
      }}>{`VoiceOver`}</a>{`. getestet. Die aktuelle Tätigkeit des Nutzers wird nicht
unterbrochen und das Alert verschwindet nach einiger Zeit.`}</p>
    <h2>{`Details der Implementierung`}</h2>
    <p>{`Schauen wir uns nun die verschiedenen Bausteine an, mit denen ich die barrierefreien, animierten Alerts gestaltet habe.`}</p>
    <h3>{`Dynamisches HTML erzeugen`}</h3>
    <p>{`Ich habe einen `}<a parentName="p" {...{
        "href": "https://angular.io/guide/architecture-services"
      }}>{`Angular-Service`}</a>{` implementiert, der das Popover-Element
mit JavaScript-Methoden wie `}<InlineCode mdxType="InlineCode">{`createElement()`}</InlineCode>{` und `}<InlineCode mdxType="InlineCode">{`appendChild()`}</InlineCode>{` dynamisch
erzeugt. Im `}<a parentName="p" {...{
        "href": "https://github.com/alexlehner86/accessible-popover-alert/blob/main/src/app/services/alert.service.ts"
      }}>{`Demo-Code`}</a>{`
findet ihr weitere Details. Ihr müsst nicht mit dem Angular-Framework vertraut sein, um das Wichtigste zu verstehen.`}</p>
    <p>{`Der folgende HTML-Code wird einem Container am Ende des body-Elements hinzugefügt:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`<div popover="manual" role="alert" class="alert top-right">
    This is a very important message
</div>`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Das `}<InlineCode mdxType="InlineCode">{`popover`}</InlineCode>{`-Attribut verwandelt das `}<InlineCode mdxType="InlineCode">{`div`}</InlineCode>{`-Element in ein Popover, das
zunächst vom Browser ausgeblendet wird. Wir zeigen das Popover-Alert an, indem wir die Methode
`}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/showPopover"
      }}>{`showPopover()`}</a>{` des Elements aufrufen.`}</p>
    <p>{`Mittels `}<InlineCode mdxType="InlineCode">{`popover="manual"`}</InlineCode>{` definieren wir einen manuellen Popover-Status für das Element. Damit
verhindern wir Light-Dismiss, also das automatische Schließen des Popovers, wenn Nutzer:innen mit anderen Elementen auf der
Seite interagieren. Stattdessen schließen wir das Popover programmatisch mit der Methode
`}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/hidePopover"
      }}>{`hidePopover()`}</a>{`.`}</p>
    <h3>{`Semantisches Markup`}</h3>
    <p>{`Setzt `}<InlineCode mdxType="InlineCode">{`role="alert"`}</InlineCode>{` auf das Popover-Element, um es als Alert zu definieren. Das Dokument
`}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/wai-aria-1.2/#alert"
      }}>{`Accessible Rich Internet Applications (WAI-ARIA) 1.2`}</a>{`
beschreibt die Alert-Rolle wie folgt:`}</p>
    <blockquote lang="en">
    A type of <BoldText mdxType="BoldText">live region</BoldText> with important, and usually time-sensitive, information. [...] Alerts are
    assertive live regions, which means <BoldText mdxType="BoldText">they cause immediate notification</BoldText> for assistive technology users.
    </blockquote>
    <p>{`Im Allgemeinen verwenden wir `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Live_Regions"
      }}>{`ARIA-Live-Regionen`}</a>{`,
um dynamische Inhaltsänderungen zu kommunizieren. Das Setzen von `}<InlineCode mdxType="InlineCode">{`role="alert"`}</InlineCode>{` hat denselben Effekt wie
das Setzen von `}<InlineCode mdxType="InlineCode">{`aria-live="assertive"`}</InlineCode>{` und `}<InlineCode mdxType="InlineCode">{`aria-atomic="true"`}</InlineCode>{`. Das heißt,
Screenreader lesen die Nachricht des Alerts sofort vor, sobald das Popover angezeigt wird.`}</p>
    <h3>{`Animation`}</h3>
    <p>{`Jetzt machen wir noch den visuellen Feinschliff und fügen dem Popover-Element eine Animation hinzu. Es gibt aber ein Problem:
Popover werden anfangs durch `}<InlineCode mdxType="InlineCode">{`display: none`}</InlineCode>{` ausgeblendet. Was für ein Albtraum! Das erzeugt gleich
zwei Hindernisse:`}</p>
    <ol>
      <li parentName="ol">{`Ein Element mit dem Wert `}<InlineCode mdxType="InlineCode">{`none`}</InlineCode>{` für die `}<InlineCode mdxType="InlineCode">{`display`}</InlineCode>{`-Eigenschaft wird nicht
gerendert. Daher hat es keine berechneten Werte für die verschiedenen CSS-Eigenschaften wie z.B. `}<InlineCode mdxType="InlineCode">{`opacity`}</InlineCode>{`.
Wenn es keinen berechneten Wert gibt, haben wir keinen Ausgangspunkt für die `}<InlineCode mdxType="InlineCode">{`transition`}</InlineCode>{`.`}</li>
      <li parentName="ol">{`Das gleiche Problem besteht auch in umgekehrter Richtung: Wenn die Eigenschaft `}<InlineCode mdxType="InlineCode">{`display`}</InlineCode>{`
auf `}<InlineCode mdxType="InlineCode">{`none`}</InlineCode>{` gesetzt wird, wird das Element sofort aus dem gerenderten HTML-Dokument entfernt, ohne dass
der `}<InlineCode mdxType="InlineCode">{`transition`}</InlineCode>{`-Effekt ausgelöst wird.`}</li>
    </ol>
    <p>{`Glücklicherweise bietet die `}<a parentName="p" {...{
        "href": "https://drafts.csswg.org/css-transitions-2/"
      }}>{`CSS Transitions Level 2`}</a>{` Spezifikation neue
Möglichkeiten für CSS-Übergänge. Sie definiert die neue `}<InlineCode mdxType="InlineCode">{`@starting-style`}</InlineCode>{` Regel:`}</p>
    <blockquote lang="en">
    The <BoldText mdxType="BoldText">@starting-style rule</BoldText> is a grouping rule. The style rules inside it are used to establish styles to
    transition from, if the previous style change event did not establish a before-change style for the element whose styles are
    being computed.
    </blockquote>
    <p>{`So können wir einen Ausgangszustand für den Übergang definieren, wenn sich der Anzeigetyp von `}<InlineCode mdxType="InlineCode">{`none`}</InlineCode>{`
zu einem anderen Typ ändert.`}</p>
    <p>{`Der zweite Puzzlestein ist die neue `}<InlineCode mdxType="InlineCode">{`transition-behavior`}</InlineCode>{`-Eigenschaft und ihr
Wert `}<InlineCode mdxType="InlineCode">{`allow-discrete`}</InlineCode>{`:`}</p>
    <blockquote lang="en">
    The <BoldText mdxType="BoldText">transition-behavior</BoldText> property specifies whether transitions will be started or not for discrete properties.
    </blockquote>
    <p>{`Diskrete Eigenschaften haben Werte, die nicht interpoliert werden können. Dies ist z.B. bei den Eigenschaften
`}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/CSS/display"
      }}>{`display`}</a>{` und `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/CSS/overlay"
      }}>{`overlay`}</a>{`
der Fall. Um Übergänge für sie zuzulassen, müssen wir den Wert `}<InlineCode mdxType="InlineCode">{`allow-discrete`}</InlineCode>{` definieren.
Ihr könnt den Wert für `}<InlineCode mdxType="InlineCode">{`transition-behavior`}</InlineCode>{` auch als Teil einer Shorthand-Deklaration angeben.`}</p>
    <p>{`Wie passt das nun alles zusammen? Meine Demo enthält die Position "top-right", bei der das Alert langsam sichtbar wird und
von rechts in den sichtbaren Bereich rutscht. Hier ist der CSS-Code für den Übergang:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`.alert[popover].top-right {
    /* Final state of the exit animation */
    opacity: 0;
    transform: translateX(100%);

    transition: opacity 0.5s,
        transform 0.5s,
        overlay 0.5s allow-discrete,
        display 0.5s allow-discrete;
}

.alert[popover].top-right:popover-open {
    /* Final state of the show animation */
    opacity: 1;
    transform: translateX(0);
}

/* Needs to be after the previous [popover]:popover-open rule to take effect, as the specificity is the same */
@starting-style {
    .alert[popover].top-right:popover-open {
        opacity: 0;
        transform: translateX(100%);
    }
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Im obigen Beispiel werden die Eigenschaften `}<InlineCode mdxType="InlineCode">{`opacity`}</InlineCode>{` und `}<InlineCode mdxType="InlineCode">{`transform`}</InlineCode>{` für
die Dauer einer halben Sekunde animiert. Wir möchten, dass der Browser den animierten Inhalt anzeigt und das Popover im
`}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Glossary/Top_layer"
      }}>{`Top Layer`}</a>{` bleibt, solange die Animation läuft. Um dies zu
erreichen, definieren wir auch `}<InlineCode mdxType="InlineCode">{`overlay 0.5s allow-discrete`}</InlineCode>{`
und `}<InlineCode mdxType="InlineCode">{`display 0.5s allow-discrete`}</InlineCode>{`.`}</p>
    <p>{`Achtung! Diese Funktionen sind experimentell und können sich in Zukunft ändern!`}</p>
    <h2>{`Browser-Unterstützung`}</h2>
    <p>{`Das `}<InlineCode mdxType="InlineCode">{`popover`}</InlineCode>{`-Attribut wird bereits `}<a parentName="p" {...{
        "href": "https://caniuse.com/mdn-api_htmlelement_popover"
      }}>{`von allen wichtigen Browsern unterstützt`}</a>{`.
Zum Zeitpunkt der Erstellung dieses Artikels hatte Firefox die Funktion nur in seinen experimentellen Nightly-Builds aktiviert.
Ich erwarte aber, dass Firefox die Funktion bald releast.`}</p>
    <p>{`Die neuen Funktionen für `}<InlineCode mdxType="InlineCode">{`transition`}</InlineCode>{` werden derzeit nur von
`}<a parentName="p" {...{
        "href": "https://caniuse.com/mdn-css_properties_transition-behavior"
      }}>{`Chrome und Edge`}</a>{` unterstützt. Ich hoffe, dass sie Teil der
`}<a parentName="p" {...{
        "href": "https://github.com/web-platform-tests/interop/tree/main/2024"
      }}>{`Interop 2024`}</a>{` sein werden und bis Ende des Jahres eine
browserübergreifende Kompatibilität erreichen.`}</p>
    <h2>{`Nützliche Links`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/WAI/ARIA/apg/patterns/alert/"
        }}>{`Alert Pattern (ARIA Authoring Practices Guide)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Web/API/Popover_API"
        }}>{`Popover API (MDN)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://drafts.csswg.org/css-transitions-2/"
        }}>{`CSS Transitions Level 2`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      